@use '../../Constants.scss';

#pick-items-modal-page {
    --keyboard-offset: 0px !important;
    --padding-start: var(--ion-padding, 16px);
    --padding-end: var(--ion-padding, 16px);
    --padding-top: var(--ion-padding, 16px);
    --padding-bottom: var(--ion-padding, 16px);

    &::part(scroll) {
        display: flex;
        flex-direction: column;
    }

    #search-bar {
        --background: #{Constants.$white};
        --border-width: 1px;
        --border-style: solid;
        --border-color: #{Constants.$neutral-gray};
        --border-radius: 3rem;
        padding-bottom: 5px;
        
        #search-input {           
            font-size: 1.25em; /* Adjust font-size as needed */
            margin: 0;
        }
        
    }

    

    .swiper {
        width: 100%;
        overflow-x: hidden;
        --swiper-scrollbar-size: 2px;
        // --swiper-scrollbar-bg-color: #{Constants.$neutral-gray};
        --swiper-scrollbar-drag-bg-color: #{Constants.$btn-next-ready}; // TODO: possibly make a new constant?
        padding-bottom: 10px;
    }

    .swiper-slide {
        width: fit-content;

        &:last-of-type {
            margin-right: 1rem; // prevents the last item from being cut off
        }

        .category-label {
            width: fit-content;
            cursor: pointer;
            font-size: 1rem;
            &.active {
                font-weight: bold;
            }
        }
    }


    #menu-list {
        width: 100%;
        flex: 1 1 0;
        overflow-y: scroll;
        scroll-behavior: smooth;

        margin: {
            left: auto;
            right: auto;
        }

        .menu-section {
            margin-bottom: 1rem;
            text-align: center;
            font-size: 1.5rem;
            font-family: 'Upheaval';

            .item-card:first-of-type {
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
            }

            .item-card:last-of-type {
                border-bottom-left-radius: 1rem;
                border-bottom-right-radius: 1rem;
            }
        }
    }
}