@use '../Constants.scss';

.forgot-password {
    text-decoration: underline;
    position: relative;
    // display: flex;
    // justify-content: flex-end;
    float: right;
    margin-bottom: 15vh;
    font-size: 12px;
    color: #727272;
    // margin-top: 2px;
    cursor: pointer;
    font-family: sans-serif;
  }

#signin-page {
    --background: #{Constants.$background-main};
    text-align: center;
    font-family: 'Upheaval';

    .challenge-text {
        font-size: 18px;
        font-family: sans-serif;
    }

    #signin-content {
        margin-top: 12.5vh;

        #logo-imgs {
            display: flex;
            justify-content: center;
            align-items: center;

            #logo-icon {
                height: 10vh;
                margin-right: 0.5rem;
            }

            #logo-text {
                height: 16vh;
            }
        }

        p {
            margin-top: 3vh;
            font-weight: 500;
        }

        #entry-options {
            width: 75%;
            margin-top: 6vh;
            margin: {
                left: auto;
                right: auto;
            }

            .login-label {
                font-family: sans-serif;
                display: flex;
                color: #7A7A7A;
                font-size: 11px;
            }
            
            #signin-btn {
                font-family: sans-serif;
                --background: #{Constants.$btn-red-orange};
                --border-radius: 14px;
                --border-width: 1px;
                --border-color: #{Constants.$btn-red-orange};
                --background: #{Constants.$btn-red-orange};
                --background-hover: #{Constants.$btn-red-orange};
                --background-activated: #{Constants.$btn-red-orange};
                width: 100%;
                margin-top: 0;
                margin-bottom: 0;
                .button-native  {
                    --border-color: red;
                }
            }

            #signup-btn {
                --border-width: 1px;
                --background: #ffe004;
                --background-color: #ffe004;
                --border-radius: 15px;
                --color: #000;
                --border-color: #ffe004;
                --background-hover: #ffe004;
                --background-activated: #ffe004;
                margin-top: 2vh;
                font-family: sans-serif;
                width: 100%;
                .button-native  {
                    --border-color: red; 
                }
            }

            .entry-field {
                margin-bottom: 1.25vh;
            }

            .email {
                margin-bottom: 4.5vh;
            }
        }
    }
}
