@use '../Constants.scss';

$border-radius: 1rem;

.start-fight-modal {
    --border-radius: #{$border-radius} #{$border-radius} 0 0;
    --ion-background-color: #{Constants.$background-main};
    --ion-toolbar-color: #{Constants.$text-on-dark};
    text-align: center;

    &.ios {
        // ios mode manually sets bottom radii to zero
        --border-radius: #{$border-radius};
    }

    ion-nav>.ion-page:not(.can-go-back)>ion-header>ion-toolbar>ion-buttons>ion-back-button {
        display: block;
        visibility: hidden;
    }

    ion-header {
        ion-toolbar {
            --background: #{Constants.$btn-next-ready};
        }

        ion-title {
            font-family: 'Upheaval';
            font-size: 1.5em;
            font-weight: 200;
        }
    }

    ion-footer {
        border-top: 1px solid Constants.$neutral-gray;

        ion-button {
            font-family: 'Upheaval';
            --background: #{Constants.$btn-next-ready};
            display: flex;
            align-items: center;
            --border-radius: .75em;

            p {
                font-size: 1.5rem; 
                margin: 0; 
            }

            &.button-disabled {
                --background: #{Constants.$btn-next-disabled};
            }
        } 

        ion-progress-bar {
            --progress-background: #{Constants.$progress-bar-active};
            --background: #{Constants.$progress-bar-background};
            height: 2rem;

            $progress-radius: 0.75rem;

            &::part(progress) {
                border-radius: 0 $progress-radius $progress-radius 0;
            }
        }
    }
}

.cols {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}