@use '../Constants.scss';

.inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  >div:nth-child(1) {
    margin-bottom: 8px;
  }

  .address-field-input {
    border: 1px solid #{Constants.$neutral-gray};
    border-radius: 10px;
    width: auto;
    height: auto;
    padding: 5px 14px;
    margin-bottom: 3px;
    width: 50%;
    text-align: center;
    color: #000;

  }

  .address-field-input.autocomplete-input {
    height: 100%;
    width: 100%;
    border: 1px solid #{Constants.$neutral-gray};
    border-radius: 10px;
  }

  .rest-modal-input:first-child {
    width: 64%;
  }

  .toggle-autocomplete-text-container {
    width: 100%;
    font-size: 1em;
  }

  .show-autocomplete-text-container {
    margin-bottom: 8px;
  }

  .toggle-autocomplete-text {
    color: #{Constants.$clickable-text};
    cursor: pointer;
    text-decoration: underline;
    margin: auto;
    font-size: 1em;
    display: table;
    background: none;
  }

  .toggle-past-addr-text {
    margin-top: 8px;
  }

  >div:nth-child(1) {
    width: 100%;
  }

  .past-address {
    display: flex;
    --background: #{Constants.$background-past-address};
    --background-hover: #{Constants.$white};
    --background-hover-opacity: 0.8;
    --background-focused: #{Constants.$white};
    --background-focused-opacity: 0.24;
    --background-activated: #{Constants.$white};
    --background-activated-opacity: 0.24;
    color: #{Constants.$dark_gray_2};
    --box-shadow: 0px 5px 5px 0px #00000040;
    --padding-top: 22px;
    --padding-bottom: 22px;
    --border-radius: 10px;
    font-size: 0.8em;
    font-family: "Orbitron";
    font-weight: 500;
    // max-width: 10rem;
    padding: 8px 5%;
    margin: 0;
    width: 100%;
  }
}
