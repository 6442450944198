@use '../Constants.scss';

.contact-us-modal {
  --background: #{Constants.$background-main};
}

.contact-us-modal-wrapper {
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  background-color: #{Constants.$background-main};
  position: relative;
  text-align: center;

  ion-item {
    --background: #{Constants.$background-main};
  }

  .contact-title {
    text-align: center;
    position: absolute;
    top: 10vh;
    width: 100%;
    font-family: 'Upheaval';
    font-size: 2.5rem;
  }

  .contact-us-item {
    background: #{Constants.$background-main};
    position: absolute;
    top: 19vh;
    --font-family: 'Upheaval';
    margin: 0 32px;
    --padding-start: 0px;
    width: 80%;
    --border-width: 0;
    --inner-border-width: 0;

    ion-item {
      --background: #{Constants.$background-main};
      width: 100%;
      --border-width: 0;
      --inner-border-width: 0;

      ion-label {
        font-size: 1.1rem;
        font-weight: bold;
        color: #{Constants.$black};
        width: fit-content;
        margin-left: 16px;
      }

      ion-radio {
        --color-checked: #{Constants.$orange_2};
        margin: 0px;
        padding: 0px;
        --border-radius: 4px;
      }
    }
  }

  .contact-us-item-msg {
    --background: #{Constants.$white};
    border: 2px solid #{Constants.$light_gray};
    border-radius: 14px;
    position: absolute;
    bottom: 20vh;
    width: 80%;
    margin: 0 32px;
    height: 40vh;
    display: flex;
    flex-direction: column;
    padding: 0;

    ion-textarea {
      --background: #{Constants.$white};
      margin: 0px;
      padding: 10px;
      flex: 1;
      font-size: 1rem;
      color: #333;
      flex: 1;
      width: 100%;
      height: 100vh;
    }
  }

  .contact-us-buttons-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 30px;
    color: #{Constants.$white};

    ion-button {
      --border-radius: 8px;
      font-weight: bold;
      --background: #{Constants.$orange_2};
    }

    .contact-close {
      color: #{Constants.$white};
      position: absolute;
      right: 32px;
      font-size: 10px;
    }

    .contact-submit {
      width: 80%;
      height: 40px;
      font-size: medium;
      color: #{Constants.$white};
      position: absolute;
      bottom: 10vh;
      margin: 0 32px;
    }
  }
}
