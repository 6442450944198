.form-content {
    width: 75%;
}

.reset-password-header {
    position: relative;
    top: 60px;
    text-align: center;
    font-size: 24px;
    box-shadow: none;
}

.reset-password-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height:100%;
    margin: auto;
    // padding-top: 5vh;
    padding-top: calc(5vh + var(--ion-safe-area-top));
    background: #FAF7EC;
    p {
        max-width: 60%;
        text-align: center;
    }
}

.data-input {
    border-radius: 28px;
    padding: 9px 30px;
    margin-bottom: 15px;
    min-height: 50px;
    &:focus {
        outline: none; 
    }
}

.form-content {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-top: 50px;
}

.next-button {
    height: 50px;
    --background: #ff451a;
    position: absolute;
    bottom: 50px;
    width: 75%;
}

.back-to-homepage {
    position: absolute;
    left: 35px;
    &:before {
        content: url('../../assets/icons/arrowLeftIcon.svg');
        cursor: pointer;
    }
}

.label {
    color: #7A7A7A;
    font-size: 11px;
    padding-bottom: 5px;
}

.logo-icon {
    position: relative;
    bottom: 40px;
    &:before {
        content: url('../../assets/tmp_logo_icon.svg')
    }
}