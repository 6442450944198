@use '../Constants.scss';

body {
  overflow: auto;
  position: relative;
}

.games-list {
  $card-side-margin: 0.75em;

  .inner-scroll {
    overflow: auto;
    position: relative;
  }

  .sports-carousel {
    display: flex;
    overflow-x: scroll;
    font-size: 1.2rem;
    gap: 1%;

    margin: {
      left: $card-side-margin;
      right: $card-side-margin;
    }
    padding-top: 5px;
    padding-bottom: 5px;

    .sport-btn {
      --background: #{Constants.$neutral-gray};
      --border-style: none;
      --padding-start: 1em;
      --padding-end: 1em;

      color: Constants.$text-on-light;
      font-size: 0.7em;
      white-space: nowrap;
      overflow: visible;

      &.active {
        --background: #{Constants.$btn-red-orange};
        color: Constants.$text-on-dark;
      }

      &::part(native) {
        overflow: visible;
      }

      p {
        font-size: 1.5em;
        margin: 0;
      }

      .beta-badge {
        position: absolute;
        right: -50%;
        top: -90%;
        z-index: 99;
        font-size: 0.8em;
        --padding-start: 7px;
        --padding-end: 7px;
        --background: #{Constants.$gloves-yellow};
        color: #{Constants.$black};
      }
    }
  }

  .games {
    .match-card {
      margin: $card-side-margin;
    }

    .match-card.selected {
      border-radius: 10px 10px 0 0;
      margin-bottom: 0;
      padding-bottom: 0;
      box-shadow: none;
    }

    .no-games-wrapper {
      display: flex;
      justify-content: left;
      text-align: left;
      margin-top: 15%;
      font-size: 1.6rem;
      .no-games {
        font-family: 'Upheaval';
      }
    }
    #sports-refer {
      margin: $card-side-margin;
      height: 25%;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      p {
        font-size: 0.5rem;
      }

      .sports-refer-button {
        min-height: 70px;
      }

      #sports-refer-btn {
        --border-radius: 0.5rem;
        --background: #{Constants.$yellow};
        --background-activated: #{Constants.$btn-red-orange};
        --color: #{Constants.$text-on-light};
        --color-activated: #{Constants.$text-on-dark};
        --padding-start: 1.5rem;
        --padding-end: 1.5rem;

        .sports-refer-image {
          max-width: 70px;

          &.left {
            position: absolute;
            left: 0%;
          }
          &.right {
            position: absolute;
            right: 0%;
          }
        }

        .sports-refer-button__text {
          display: flex;
          text-transform: none;
          flex-direction: column;
          text-align: left;
          font-weight: 700;

          .title {
            font-family: 'Upheaval';
            font-size: 17px;
            margin: 0;
            padding-bottom: 3px;
            text-align: center;
          }
        }
      }
    }
  }

  .match-card-skeleton {
    .skeletal-circle {
      border-radius: 25%;
    }
  }

  .no-games-wrapper {
    display: flex;
    justify-content: left;
    margin-left: 1rem;
    font-size: 1.6rem;
    .no-games {
      font-family: 'Upheaval';
    }
  }

  .day-filter {
    margin: {
      left: $card-side-margin;
      right: $card-side-margin;
      top: 1em;
    }
    text-align: left;

    .day-select {
      --background: #{Constants.$btn-red-orange};
      --color: #{Constants.$text-on-light};
      --padding-start: 1.5em;
      --padding-end: 1.5em;
      --border-radius: 20px;
      --text-align: center;

      width: auto;
      display: inline-block;
      font-weight: bold;

      .select-text {
        font-size: 0.75em;
        font-weight: bold;
        margin: 0;
        display: inline-block;
      }
    }
    .calendar {
      --color: #{Constants.$btn-red-orange};
      --ion-color-primary: #{Constants.$btn-red-orange};
      --ion-color-primary-rgb: 255, 87, 34;
      --ion-color-primary-contrast: #{Constants.$text-on-dark};
      --ion-color-primary-contrast-rgb: 255, 255, 255;
      --ion-color-primary-shade: #{Constants.$btn-red-orange};
      --ion-color-primary-tint: #{Constants.$btn-red-orange};
    }
    .calendar-button {
      --background: #{Constants.$btn-red-orange}; // Ensure calendar button is orange
      --color: #{Constants.$white};
      --background-activated: #{Constants.$btn-red-orange};
      --background-focused: #{Constants.$btn-red-orange};
      --background-hover: #{Constants.$btn-red-orange};
    }
  }
}
