@use '../Constants.scss';

#restaurant-page {
  --ion-background-color: #{Constants.$background-main};

  .venu-text {
    font-family: 'Upheaval';
    font-size: 1.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    // height: 148px; //maybe add if we want the same height as delivery inputs
    display: flex;
    align-items: center;
  }

  #restaurant-grid {
    --ion-grid-columns: 3;
  }

  .past-addresses-text {
    text-align: center;
    font-size: 1.2em;
    font-family: "Orbitron";
    font-weight: 500;
    color: #{Constants.$dark_gray_2};
    margin: 10px auto;
    display: table;
    position: relative;
    // text-decoration-line: underline;
    // text-decoration-thickness: 2px;

    &::after {
      content: '';
      height: 2px;
      width: 90%;
      background: #{Constants.$dark_gray_2};
      position: absolute;
      left: 5%;
      bottom: 0px;
    }
  }

  .past-address {
    display: flex;
    --background: #{Constants.$background-past-address};
    --background-hover: #{Constants.$white};
    --background-hover-opacity: 0.8;
    --background-focused: #{Constants.$white};
    --background-focused-opacity: 0.24;
    --background-activated: #{Constants.$white};
    --background-activated-opacity: 0.24;
    color: #{Constants.$dark_gray_2};
    --box-shadow: 0px 5px 5px 0px #00000040;
    --padding-top: 22px;
    --padding-bottom: 22px;
    --border-radius: 10px;
    font-size: 0.8em;
    font-family: "Orbitron";
    font-weight: 500;
    // max-width: 10rem;
    padding: 8px 5%;
    margin: 0;
    width: 100%;
  }
}

.restaurant-segment {
  border-bottom: 0.75px solid #c7c7c5;
}

.restaurant-segment-button {
  --color: #a0a0a0;
  --color-checked: #5f5f5f;
  --indicator-color: #ff451a;
  --indicator-height: 5px;
  text-align: center;
  font-family: 'Upheaval';
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 20px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.5px;
  position: relative;
  color: var(--color);

  &.checked {
    color: var(--color-checked);
    border-bottom: var(--indicator-height) solid var(--indicator-color);
  }

  &:not(.checked) {
    border-bottom: none;
  }
}

.rest-tile {
  h1 {
    font-family: 'Upheaval';
    text-align: center;
  }
}

.delivery-save-button {
  display: flex;
  --background: #e75432;
  color: #fff;
  --box-shadow: 0px 5px 5px 0px #00000040;
  max-width: 10rem;
  padding: 5px;
  margin: 0 auto;
}

.search-wrap {
  background-color: #faf7ec;
  margin-top: 8px;

  .searchbar-search-icon {
    margin-right: 10%;
  }

  .searchbar-input {
    padding-left: 15%;
  }

  .apptabs-header {
    width: 15%;
    position: relative;
    top: 8px;
    right: 10px;
  }
}

.ff-icon {
  width: 15%;
}

.restaurant-search {
  padding-left: 10px;
}

.restaurant-map {
  width: 40%;
  display: flex;
  justify-self: center;
  margin: 1rem auto;
}

@media (max-width: 768px) {
  .restaurant-map {
    width: 75%;
    >div {
      height: 350px!important;
    }
  }
}

.gmnoprint {
  display: none;
}