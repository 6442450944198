@use '../Constants.scss';
#wrapper{
    display: flex;
    justify-content: center;
    width: 75%;
    margin: {
        left: auto;
        right: auto;
        bottom: 3%;
    }

    #code-input {
        border-bottom: 1px solid #{Constants.$text-on-light};
        text-align: center;
        margin: 4px;
        width: 2em;
    }
}

