@use '../Constants.scss';

$border-radius: 1rem;

.notification-page {
  --border-radius: #{$border-radius} #{$border-radius} 0 0;
  --ion-background-color: #{Constants.$background-main};
  --ion-toolbar-color: #{Constants.$text-on-dark};

  @keyframes slideInFromRight {
    0% {
      transform: translate(100%, 0);
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  &.not-modal-slide-in {
    animation: slideInFromRight 0.5s ease-in-out forwards;
    transform: translate(100%, 0);
  }

  @keyframes slideOutToRight {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    100% {
      transform: translate(100%, 0);
      opacity: 0;
    }
  }

  &.not-modal-slide-out {
    animation: slideOutToRight 0.5s ease-in-out forwards;
    transform: translate(100%, 0);
  }

  .close-button {
    --background: #{Constants.$btn-next-ready};
  }

  ion-header {
    ion-toolbar {
      display: flex;
      justify-content: space-between;
      --background: #{Constants.$btn-next-ready};
      padding: 0.5rem;
    }

    ion-title {
      font-family: 'Upheaval';
      font-size: 1.5em;
      font-weight: 200;
      white-space: normal;
      overflow: visible;
      text-overflow: unset;
      flex-grow: 1;
    }
  }

  .centered-title {
    text-align: center;
  }

  .segments {
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    .notification-segment {
      width: 100%;
      justify-content: space-around;
      .segment-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;
        --color: #{Constants.$medium-gray};
        --color-checked: #{Constants.$dark_gray_2};
        --indicator-color: #{Constants.$btn-red-orange};
        --indicator-height: 5px;
        text-align: center;
        font-family: 'Upheaval';
        font-feature-settings:
          'clig' off,
          'liga' off;
        font-size: 20px;
        font-style: normal;
        line-height: 22px;
        letter-spacing: -0.4px;
        text-transform: none;
        letter-spacing: 0.5px;

        .label-badge-container {
          position: relative;
          display: flex;
          align-items: center;
          flex: 1;

          .chat-label {
            margin: 0 auto;
            position: relative;
            font-size: large;
          }

          .recent-badge {
            position: absolute;
            top: 1px;
            right: -14px;
            background-color: #{Constants.$btn-red-orange};
            color: #{Constants.$white};
            padding: 0.2rem 0.8rem;
            font-size: 0.6em;
            border-radius: 50%;
            width: 20px;
            height: 23px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
          }
        }
      }
    }
  }

  .loader-time-header {
    padding: 0 1rem;
    text-align: left;
    color: #{Constants.$black};
    font-weight: bold;
  }

  .tab-content {
    padding: 0 1rem;
    text-align: left;

    .time-header {
      color: #{Constants.$black};
      font-weight: bold;
    }

    ion-list {
      ion-item {
        --background: #e5e1d7;
        --margin-bottom: 5px;
        --border-radius: 15px;
      }
    }
    .msg-item {
      margin-left: 0;
      padding-left: 0;
      margin-bottom: 8px;
    }

    .item-native {
      padding-left: 0;
    }

    .msg-item .msg-avatar {
      margin-left: 0;
      margin-right: 8px;
    }

    .msg-header {
      font-size: large;
    }

    .msg-content {
      color: #{Constants.$dark_gray};
    }

    --ion-label {
      width: 80%;
    }

    .msg-time {
      color: #{Constants.$dark_gray_2};
      text-align: right;
      font-size: x-small;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .recent-close-button {
    top: 0px;
    font-size: 15px;
  }

  .new-chat-button {
    position: absolute;
    bottom: 15px;
    right: 0px;
    margin: 0 15px;
    --background: #{Constants.$btn-red-orange};
    font-size: medium;
    font-weight: bold;
  }

  .spacing-btn {
    visibility: hidden;
  }
}
