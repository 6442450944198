.notification-container {
  position: relative;
}

.notification {
  position: relative;
}

.badge {
  position: absolute;
  top: 15px;
  right: 10px;
  background-color: red;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  z-index: 10; // Ensure it overlays the toolbar
}
