// ********************
// LEGACY COLOR PALETTE
// ********************

// background colors
$background_primary: #fffad6;
$background_secondary: #eb5529;

// blues
$dark_blue: #333678;
$medium_blue: #888abe;
$light_blue: #f3f6ff;

// yellows
$orange_yellow: #ffa41d;
$yellow: #fff094;
$light_yellow: #fffad6;
$white_yellow_2: #fffef9;
$white_yellow: #fcfcf0;
$gloves_yellow: #ffe000;

// oranges
$dark_orange: #b7310a;
$orange: #eb5529;
$light_orange: #f27059;
$orange_red: #ff451a;
$orange_2: #e75432;

// misc
$purple: #821f93;
$green: #51d245;

// grayscales
$black: #000000;
$dark_gray: #494949;
$dark_gray_2: #5f5f5f;
$dark_gray_3: #5e5e5e;
$gray: #676767;
$light_gray: #9f9f9f;
$light_gray_2: #acacac;
$light_gray_3: #d9d9d9;
$light_gray_4: #f0f0f0;

$white_gray: #c3c3c3;
$white: #ffffff;
$white_2: #f2f2f2;

// *******************
//  NEW COLOR PALETTE
// *******************

// colors
$white: #ffffff;
$black: #000000;
$neutral-gray: #d9d9d9;
$medium-gray: #a0a0a0;
$dark-gray: #464646;

// backgrounds
$background-main: #faf7ec;
$background-card: #f4dfc7;
$background-title: #e75432;
$background-navbar: #ffffff;
$background-rest-card: #f4e3a5;
$background-selected-card: #ff451a;
$background-rest-modal: #fffef9;
$background-profile-page: #fffef9;
$background-profile-list: #fff2c3;
$background-find-friend: #fff6cb;
$background-header-search: #f2f2f2;
$background-friends-list-item: #f6f6f6;
$background-past-address: #f4e0c7;

// text
$text-on-light: #4d4d4d;
$text-on-dark: #ffffff;
$text-on-color: #fff0d8;
$border-for-text: #000000d9;
$clickable-text: #fe727e;

// buttons
$btn-tab: #d9d9d9;
$btn-red-orange: #ff451a;
$btn-next-ready: #e75432;
$btn-next-disabled: #9f9f9f;
$btn-following: #ffe000;

// icons
$icon-settings: #eb5529;

// misc
$progress-bar-active: #eb5529;
$progress-bar-background: #ffb39c;
$badge-sports: #d9d9d9;
$badge-rest-accent: #c93715;

// success colors
$win-green: #89b865;
$loss-red: #ff451a;
$tie-yellow: #ff9d43;
