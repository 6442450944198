@use '../Constants.scss';

#signup-page {
    --background: #{Constants.$background-main};
    text-align: center;    

    #signup-content {
        margin-top: 6vh;

        .signup-header {
            width: 75%;
            margin: auto;
            position: relative;

            .signup-back-button {
                position: absolute;
                left: 0;
                // height: 7.25vh;

                .signup-back-icon {
                    color: #{Constants.$dark-gray};
                }
            }
        }

        #logo-icon {
            height: 7.25vh;
        }

        h2 {
            margin-top: 1vh;
        }

        #entry-options {
            width: 75%;
            margin: {
                left: auto;
                right: auto;
            }

            p {
                text-align: left;
                font-size: 0.75rem;
                color: Constants.$text-on-light;

                margin-bottom: 0.25rem;

                &:not(:first-of-type) {
                    margin-top: 0.75rem;
                }
            }

            #resend-btn {
                text-decoration: underline;
                color: #{Constants.$btn-red-orange};
                cursor: pointer;
            }
            

            #signup-btn {
                --background: #{Constants.$btn-red-orange};
            }

            #agreements {
                text-align: left;
                color: Constants.$text-on-light;
                font-size: 0.75rem;
                display: flex;
                flex-direction: column;

                // XXX: maybe make a global property?
                a {
                    color: blue;
                    text-decoration: underline;

                    &:hover {
                        cursor: pointer;
                    }
                }

                margin: {
                    top: 1.25rem;
                    bottom: 1.25rem;
                }

                div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    ion-label {
                        margin-left: 0.25rem;
                    }

                    ion-checkbox {
                        --border-width: 0.1rem;
                        --border-radius: 0.25rem;

                        &:not(:last-of-type) {
                            margin-bottom: 0.75vh;
                        }
                    }
                }
            }
        }
    }
}

.password-req {
    font-size: 0.75rem;
    color: #7a7a7a;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .password-req ul {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .password-req .item {
    padding-left: 10px;
    margin-bottom: 5px;
    color: #ff0000;
  }
  
  .password-req .item::marker {
    content: none;
  }
  
  .password-req .green {
    color: #10a215;
  }
  
  .password-req .item::before {
    content: url('../../assets/passwordcheck.svg');
    position: relative;
    top: 1px;
    right: 10px;
  }
  
  .password-req .green::before {
    content: url('../../assets/password_green_check.svg');
    position: relative;
    top: 1px;
    right: 10px;
  }